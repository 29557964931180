<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <v-form ref="formPersonal">
          <h1>
            <v-icon class="iconTitle">mdi-account-group</v-icon> Ficha de
            personal
          </h1>
          <br />
          <v-layout wrap>
            <v-flex xs11>
              <v-autocomplete
                v-model="selectPersonal"
                :items="this.$store.state.personal"
                item-text="nombre"
                item-value="id"
                flat
                hide-no-data
                label="Buscar trabajador"
                prepend-inner-icon="mdi-account-search"
                solo-inverted
                @change="
                  getPersonal();
                  getFirmas();
                "
              ></v-autocomplete>
            </v-flex>
            <v-flex xs1>
              <v-btn v-on:click="clear" text class="btnMini">
                <v-icon large>mdi-plus</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs4>
              <v-text-field
                label="Nombre"
                outlined
                v-model="nombre"
                :rules="rules.required"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field label="CIF" outlined v-model="cif"></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Teléfono"
                outlined
                v-model="telefono"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Email"
                outlined
                v-model="email"
                :rules="rules.required"
                :disabled="isDisabledLogin"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs4>
              <v-text-field
                label="Dirección"
                outlined
                v-model="direccion"
              ></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field
                label="Código postal"
                outlined
                v-model="cp"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Localidad"
                outlined
                v-model="localidad"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Provincia"
                outlined
                v-model="provincia"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <h2 style="margin: 10px 0">Datos de acceso a oliCloud</h2>
          <v-layout>
            <v-flex xs3>
              <v-text-field
                label="Usuario"
                outlined
                v-model="email"
                :rules="rules.required"
                :disabled="isDisabledLogin"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-text-field
                label="Contraseña"
                outlined
                v-model="contrasena"
                :rules="rules.required"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
              ></v-text-field>
            </v-flex>
            <v-flex xs3>
              <v-select
                label="Tipo de usuario"
                v-model="tipo"
                outlined
                :items="['Trabajador', 'Encargado']"
                :disabled="isDisabledLogin"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="btnCustom save" :loading="loading" @click="savePersonal"
          ><v-icon class="btnIcon">mdi-content-save</v-icon> Guardar</v-btn
        >
        <v-btn
          class="btnCustom delete"
          :disabled="isDisabledButton"
          @click="deletePersonal"
          ><v-icon class="btnIcon">mdi-delete</v-icon> Eliminar</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          class="btnCustom"
          :disabled="isDisabledButton"
          @click="crearFirma"
          ><v-icon class="btnIcon">mdi-draw</v-icon></v-btn
        >
        <v-btn
          class="btnCustom"
          :disabled="isDisabledButton"
          @click="schedule"
          ><v-icon class="btnIcon">mdi-book-clock</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table :headers="headers" :items="firmas">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="editarFirma(item)"> mdi-pencil </v-icon>
            <v-icon small @click="eliminarFirma(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import { BASE_URL } from "../components/constants.js";
import * as basics from "../components/basics.js";
import showMessage from "../components/Messages.vue";
import showDialog from "../components/Dialog.vue";
import genericReload from "../components/genericReload";
export default {
  name: "Personal",
  data() {
    return {
      rules: {
        required: [
          (val) => (val || "").length > 0 || "Este campo es obligatorio",
        ],
      },
      loading: false,
      showPass: true,
      selectPersonal: null,
      nombre: "",
      cif: "",
      telefono: "",
      email: "",
      direccion: "",
      cp: "",
      localidad: "",
      provincia: "",
      contrasena: "",
      tipo: "Trabajador",
      horario: null,
      isDisabledLogin: false,
      isDisabledButton: true,
      headers: [
        {
          text: "Hora entrada",
          align: "start",
          sortable: true,
          value: "fecha1",
        },
        { text: "Hora salida", value: "fecha2" },
        { text: "Ordinarias", value: "ordinarias" },
        { text: "Complementarias", value: "complementarias" },
        { text: "Extra", value: "extra" },
        { text: "Pausadas", value: "pausadas" },
        { text: "Método de entrada", value: "entrada" },
        { text: "Método de salida", value: "salida" },
        { text: "", value: "actions", sortable: false },
      ],
      firmas: [],
      horarios: [],
    };
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted() {},
  methods: {
    async savePersonal() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.auth,
      };
      let response, data;
      this.loading = true;
      if (this.selectPersonal == null) {
        data = {
          nombre: this.nombre,
          DNI: this.cif,
          email: this.email,
          telefono: this.telefono,
          direccion: this.direccion,
          localidad: this.localidad,
          provincia: this.provincia,
          cp: this.cp,
          contrasena: this.contrasena,
          tipoUsuario: this.tipo,
        };
        response = await fetch(`${BASE_URL}/personal`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });
      } else {
        data = {
          nombre: this.nombre,
          DNI: this.cif,
          telefono: this.telefono,
          direccion: this.direccion,
          localidad: this.localidad,
          provincia: this.provincia,
          cp: this.cp,
          email: this.email,
          contrasena: this.contrasena,
        };
        response = await fetch(`${BASE_URL}/personal/${this.selectPersonal}`, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(data),
        });
      }
      switch (response.status) {
        case 200:
          this.$refs.snackbar.setNew(
            true,
            "Datos guardados correctamente",
            "Ok",
            "green"
          );
          this.clear();
          await this.$store.dispatch("getPersonal");
          this.loading = false;
          break;
        default:
          this.$refs.snackbar.setNew(
            true,
            "ERROR. Datos no guardados",
            "Cerrar",
            "red"
          );
          this.loading = false;
          break;
      }
    },
    async getPersonal() {
      if (this.selectPersonal != null) {
        const response = await genericReload(
          this.$store.state.auth,
          `personal/${this.selectPersonal}`
        );
        if (response.status === 200) {
          const json = await response.json();
          this.nombre = json.body[0].nombre;
          this.cif = json.body[0].DNI;
          this.email = json.body[0].email;
          this.telefono = json.body[0].telefono;
          this.localidad = json.body[0].localidad;
          this.direccion = json.body[0].direccion;
          this.provincia = json.body[0].provincia;
          this.cp = json.body[0].cp;
          this.tipo = json.body[0].tipoUsuario;
          this.horario = json.body[0].horario;
          this.isDisabledLogin = true;
          this.isDisabledButton = false;
        } else {
          this.$refs.snackbar.setNew(
            true,
            "ERROR. No se han podido cargar los datos del trabajador",
            "Cerrar",
            "red"
          );
        }
      }
    },
    clear() {
      this.$refs.formPersonal?.reset();
      this.isDisabledLogin = false;
      this.isDisabledButton = true;
      this.selectPersonal = null;
      this.firmas = [];
    },
    deletePersonal() {
      this.$refs.dialog
        .setNew(
          "Eliminar trabajador",
          "¿Estás seguro de querer eliminar este trabajador?",
          0
        )
        .then(async (confirm) => {
          if (confirm.response) {
            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.auth,
            };

            const response = await fetch(
              `${BASE_URL}/personal/${this.selectPersonal}`,
              {
                method: "DELETE",
                headers: headers,
              }
            );
            switch (response.status) {
              case 200:
                this.$refs.snackbar.setNew(
                  true,
                  "Trabajador eliminado correctamente",
                  "Ok",
                  "green"
                );
                this.clear();
                await this.$store.dispatch("getPersonal");
                break;
              default:
                this.$refs.snackbar.setNew(
                  true,
                  "ERROR. Trabajador no eliminado",
                  "Cerrar",
                  "red"
                );
                break;
            }
          }
        });
    },

    async getFirmas() {
      if (this.selectPersonal != null) {
        const response = await genericReload(
          this.$store.state.auth,
          `firma/?idPersonal=${this.selectPersonal}`
        );
        if (response.status === 200) {
          const json = await response.json();
          for (let i = 0; i < json.body.length; i++) {
            if (!json.body[i].working) {
              this.firmas.push({
                fecha1:
                  basics.formatDate(json.body[i].entryDate, "dd/mm/yyyy") +
                  " " +
                  json.body[i].entryHour,
                fecha2:
                  basics.formatDate(json.body[i].exitDate, "dd/mm/yyyy") +
                  " " +
                  json.body[i].exitHour,
                ordinarias: json.body[i].ordinaryHours,
                complementarias: json.body[i].complementaryHours,
                extra: json.body[i].extraHours,
                pausadas: json.body[i].pausedHours,
                entrada: basics.getSignatureType(json.body[i].entryMethod),
                salida: basics.getSignatureType(json.body[i].exitMethod),
              });
            } else {
              this.firmas.push({
                fecha1:
                  basics.formatDate(json.body[i].entryDate, "dd/mm/yyyy") +
                  " " +
                  json.body[i].entryHour,
                fecha2: "Trabajando",
                ordinarias: "",
                complementarias: "",
                extra: "",
                pausadas: "",
                entrada: basics.getSignatureType(json.body[i].entryMethod),
                salida: "",
              });
            }
          }
        } else {
          this.$refs.snackbar.setNew(
            true,
            "ERROR. No se han podido cargar los datos de firma para este trabajador",
            "Cerrar",
            "red"
          );
        }
      }
    },
    crearFirma() {
      const working = this.firmas.filter((signature) => signature.working === true);
      let title = "";
      if(working.length==0){
        title = "Firmando entrada";
      }else{
        title = "Firmando salida";
      }
      this.$refs.dialog
        .setNew(title, "", "signature", 0)
        .then(async (confirm) => {
          if (confirm.response) {
            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.auth,
            };

            const data = {
              idPersonal: this.selectPersonal,
              tipo: 1,
              idCliente: 1,
              password: confirm.password,
              email: this.email
            }

            const response = await fetch(
              `${BASE_URL}/firma`,
              {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data),
              }
            );
            const json = await response.json();
            console.log( json.body);
            switch (json.body) {
              case "not schedule":
                this.$refs.snackbar.setNew(
                  true,
                  "ERROR. Firma no registrada. El trabajador no tiene ningún horario asignado",
                  "Cerrar",
                  "red"
                );
                break;
              case "created correctly":
                this.$refs.snackbar.setNew(
                  true,
                  "Firma registrada correctamente",
                  "Ok",
                  "green"
                );
                break;
              case "error":
                this.$refs.snackbar.setNew(
                  true,
                  "ERROR. Firma no registrada",
                  "Cerrar",
                  "red"
                );
                break;
              default:
                this.$refs.snackbar.setNew(
                  true,
                  "ERROR. Firma no registrada. Revise que la contraseña sea correcta",
                  "Cerrar",
                  "red"
                );
                break;
            }
          }
        });
    },
    editarFirma() {},
    eliminarFirma() {},
    async getSchedules() {
      const response = await genericReload(
        this.$store.state.auth,
        `horario`
      );
      if (response.status === 200) {
        const json = await response.json();
        return json.body;
      }
    },
    async schedule() {
      this.$refs.dialog
        .setNew("Horarios", "", "schedule", [this.horario, await this.getSchedules()])
        .then(async (confirm) => {
          if (confirm.response) {
            const headers = {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.auth,
            };

            const data = {
              nombre: this.nombre,
              idHorario: confirm.idHorario
            }

            const response = await fetch(
              `${BASE_URL}/personal/${this.selectPersonal}`,
              {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data),
              }
            );
            switch (response.status) {
              case 200:
                this.$refs.snackbar.setNew(
                  true,
                  "Horario asignado correctamente",
                  "Ok",
                  "green"
                );
                break;
              default:
                this.$refs.snackbar.setNew(
                  true,
                  "ERROR. Horario no asignado",
                  "Cerrar",
                  "red"
                );
                break;
            }
          }
        });
    },
  },
};
</script>
<style scoped>
.v-input {
  margin-right: 1em;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>